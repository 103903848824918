.FloatingView {
    position: absolute;
    bottom: -20px;
    right: -20px;
    width: 100px;
    height: 100px;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    z-index: 1000;
    cursor: pointer;
    overflow-x: hidden;
    will-change: width, height, contents, scroll-position;

    .label {
        line-height: 100px;
        text-align: center;
        color: #FFF;
        display: block;
        font-size: .85em;
        font-weight: 500;
        text-transform: uppercase;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
    }
}

body.floating-view-active {
    .FloatingView {
        overflow-y: scroll;
        cursor: default;

        > .label {
            line-height: 2.8;
            border-bottom: solid 1px rgba(255, 255, 255, 0.50);
        }
    }

    nav.nav-mobile {
        ul {
            opacity: 0;
            pointer-events: none;
        }
    }

    .page {
        > .layouts {
            filter: blur(3px);
            transform: scale(.99);

            * {
                pointer-events: none;
            }
        }

        > .Background {
            filter: blur(5px);
            transform: scale(.97);
        }
    }

    .floating-view-overlay {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .App > nav.nav-desktop {
        pointer-events: none;
    }
}