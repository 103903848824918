.OptionsPanel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    box-shadow: rgb(0, 0, 0) 0px 0px 30px;
    border: solid 3px rgba(255, 255, 255, 0.03);
    background-color: rgba(255, 255, 255, 0.004);
    border-radius: 30px;
    padding: 0px 30px;
    box-sizing: border-box;
    margin-top: 0px;
    color: #FFF;

    > div {
        flex-basis: 50%;
        padding: 30px 0px;
        cursor: pointer;
        position: relative;

        svg {
            padding-bottom: 10px;
            transition: transform .10s;
        }

        &:after {
            content: "";
            width: 20px;
            height: 20px;
            background-color:rgba(0, 0, 0, 0.5);
            position: absolute;
            right: 0px;
            top: 30px;
            border-radius: 30px;
            border: solid 1px rgb(254, 105, 241);
            box-sizing: border-box;
        }

        &.option-active {
            &:after {
                background: linear-gradient(to right, #FF3CAC, #8700ff);
            }
        }

        &:not(.option-active) {
            h2 {
                background: #FFF;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: rgba(255, 255, 255, 0);
            }
        }

        &:not(.disabled):hover {
            svg {
                transform: scale(1.15)
            }
        }

        &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }
    }

    .option-1 {
        border-bottom: solid 2px rgba(255, 255, 255, 0.03);
    }

    h2 {
        margin-bottom: 5px;
        font-size: 1.30em;
    }

    .icons {
        font-size: 3em;
    }

    p {
        line-height: 1.85em;
        margin: 0;
        font-size: 0.90em;
    }
}