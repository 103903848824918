.IconLinks {
    display: block;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.01em;
    }

    li {
        display: inline-block;
        padding: 0px 30px 0px 0px;
    }

    a {
        box-sizing: border-box;
    }
}