.layouts {
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 30px;
    padding: 30px;
    box-sizing: border-box;

    &.scroll-y {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    /* col 1 */
    &.layout-col-1 {
        grid-template-columns: 1fr;

        > .col-1 {
            margin: 0 auto;
            width: 100%;
        }

        &.border-right {
            > .col-1 {
                border-right: solid 3px;
                border-right-color: inherit;
            }
        }
    }

    /* col 2 */
    &.layout-col-2 {
        &.narrow-col-1 {
            grid-template-columns: 1fr 1.5fr;
        }

        &.narrower-col-1 {
            grid-template-columns: 400px 1fr;
        }
    }

    > div {
        box-sizing: border-box;
        position: relative;
        border-color: inherit;

        &.center-col {
            align-self: center;
        }
    }

    /* col 3 alt */
    &.layout-col-3-alt {
        box-sizing: border-box;
        padding: 15px;
        grid-row-gap: 10px;
        grid-column-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        > .col-1 {
            grid-column: 1 ;
            grid-row: 1 / 3;

            > .ItemBanner img {
                height: auto;
                width: 100%;
            }
        }

        > .col-2 {
            grid-column: 1 / 3;
            grid-row: 2;
            grid-column-end: 4;
            grid-column-start: 2;
        }

        > .col-3 {
            grid-column: 2 ;
            grid-row: 1 ;
        }

        > .col-4 {
            grid-column: 3;
            grid-row: 1;
        }

        /* reversed */
        &.layout-reversed {
            > .col-1 {
                grid-column: 3 ;
            }
    
            > .col-2 {
                grid-column-end: 3;
                grid-column-start: 1;
            }
    
            > .col-3 {
                grid-column: 1 ;
            }
    
            > .col-4 {
                grid-column: 2;
            }
        }
    }

    &.padding-off {
        padding: 0;
        background-color: transparent;
        border: 0;
    }

    /* scrollbar */
    &::-webkit-scrollbar {
        width: 1px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #FFF;
    }
}

/* vertical-cols */
.vertical-cols {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* media queries */
@media screen and (max-width: 930px) {
    .layouts {
        &.layout-col-2.narrow-col-1, &.layout-col-2.narrower-col-1 {
            grid-template-columns: 1fr;
        }

        &.layout-col-3-alt {
            grid-template-columns: 1fr 1fr;

            > .col-1 {
                grid-row: 1;
            }
    
            > .col-2 {
                grid-column: 1;
                grid-row: 2;
            }
    
            > .col-4 {
                grid-column: 2;
                grid-row: 2;
            }

            &.layout-reversed {
                > .col-1 {
                    grid-column: 2;
                }

                > .col-2 {
                    grid-column: 1;
                    grid-row: 2;
                }
            }

            /* item banner border radius */
            > div {
                &.col-1 > .ItemBanner {border-top-left-radius: 20px; border-bottom-left-radius: 0px;}
                &.col-3 > .ItemBanner {border-top-right-radius: 20px;}
                &.col-2 > .ItemBanner {border-bottom-left-radius: 20px; border-bottom-right-radius: 0px;}
                &.col-4 > .ItemBanner {border-top-right-radius: 0px; border-bottom-right-radius: 20px;}
            }
        }

        > div.center-col {
            align-self: end;
        }
    }

    /* about me my intro */
    .MyIntro {
        .my-bio {
            margin: 15px 0px;
        }
    }

    /* about-me portrait */
    img.my-portrait {
        top: -50px;
        right: 0;
        left: 0;
        height: 400px !important;
        margin: 0 auto;
        -webkit-mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 100px, #FFF 290px);
        mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 100px, #FFF 290px);
    }

    /* Background */
    .Background {
        .Circle, .GridDots {
            display: none;
        }
    }

    /* options panel */
    .OptionsPanel {
        height: auto !important;
    }
}

@media screen and (max-width: 600px) {
    .layouts {
        &.layout-col-3-alt {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;

            > .col-1 {
                grid-row: 1;
                grid-column: 1;
            }
    
            > .col-2 {
                grid-row: 2;
                grid-column: 1;
            }

            > .col-3 {
                grid-row: 3;
                grid-column: 1;
            }
    
            > .col-4 {
                grid-row: 4;
                grid-column: 1;
            }

            &.layout-reversed {
                > .col-1 {
                    grid-column: 1;
                    grid-row: 1;
                }

                > .col-4 {
                    grid-row: 4;
                    grid-column: 1;
                }
            }

            /* item banner border radius */
            > div {
                 > .ItemBanner {
                    border-radius: 0 !important;
                }
                &.col-1 > .ItemBanner {border-top-left-radius: 30px !important; border-top-right-radius: 30px !important;}
                &.col-4 > .ItemBanner {border-bottom-left-radius: 30px !important; border-bottom-right-radius: 30px !important;}
            }
        }
    }
}