html, body {
  height: 100vh;
  min-width: 320px;
}

a {
  text-decoration: none;
  color: inherit;

  &.contact-mailto {
    color: #2e3646;

    &:hover {
      text-decoration: underline;
    }
  }
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(40,40,40);
  background: radial-gradient(circle, #1b1d28 0%, rgba(0,0,0,1) 100%);
}

#root {
  height: 100vh;
  display: flex;
  vertical-align: middle;;
}

#cursor {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: #FFF;
  position: absolute;
  margin-left: -15px;
  margin-top: -15px;
  z-index: 1000;
  mix-blend-mode: difference;
  pointer-events: none;
  box-sizing: border-box;
  overflow: hidden;
  transition-property: border-width;
  transition-duration: .10s;

  &:before {
    content: "";
    border-radius: 7px;
    width: 7px;
    height: 7px;
    left: 11px;
    top: 11px;
    position: absolute;
    background-color: #FFF;
  }

  &.clickable-style {
    border-width: 15px;

    &:before {
      display: none;
      left: -15px;
      top: -15px;
    }
  }
}

body:not(:hover) {
  #cursor {
    display: none;
  }
}

#new-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  background-image: url('../src/assets/diagonal.svg');
}

// not found
.not-found {
  text-align: center;
  color: #FFF;

  h1 {
    font-size: 10em;
    margin: 0;
    filter: blur(7px);
    line-height: 1;
  }
}