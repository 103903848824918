.Background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: -1;
    border-radius: 30px;
    overflow: hidden;
}

/* GridLines */
.GridLines {
    -webkit-mask-image: linear-gradient(to left, transparent 0%, #FFF 20%);
    mask-image: linear-gradient(to left, transparent 0%, #FFF 20%);
}

/* grid dots */
.GridDots {
    position: absolute;

    &.centered-griddots {
        left: 0px;
        right: 0px;
        margin: 0 auto;
    }
}

/* circle */
.Circle {
    border-radius: 100%;
    position: absolute;
    background-color: rgba(74, 79, 103, 0.07);
}

/* image */
.Image {
    width: 100%;
    height: 500px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;

    &.fade {
        -webkit-mask-image: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.15) 100%);
        mask-image: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.15) 100%);
    }
}