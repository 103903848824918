.List {
    border-left: solid 1px rgba(255, 255, 255, 0.50);
    list-style: none;
    padding-left: 10px;
    padding-top: 20px;
    margin-top: 0px;
    margin-bottom: 0px;

    li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 10px;
        background-color: rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        border-radius: 5px;
        transition: background-color .10s;

        &:before {
            content: "";
            position: absolute;
            top: 0px;
            bottom: 0px;
            margin: auto 0;
            left: -5px;
            width: 10px;
            height: 1px;
            color: #FFF;
            background-color: rgba(255, 255, 255, 0.50);
            transition-property: width, background-color;
            transition-duration: .10s;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.25);

            &:before {
                width: 15px;
                background-color: #FFF;
            }
        }
    }

    a {
        display: block;
        padding: 5px 0px;
        transition: margin-left .10s;

        &:hover {
            margin-left: 5px;
            color: #FFF;
        }
    }

    .FloatingView & {
        color: #FFF;
        height: 100%;
        margin-left: 20px;
        margin-right: 20px;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
    }
}