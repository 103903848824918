.BlogList {
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    > li:not(:last-child) {
        border-bottom: solid 3px #ffffff08;
    }

    a {
        padding: 60px 30px 60px 250px;
        display: block;
        position: relative;
        overflow: hidden;
        transition-property: padding-left;
        transition-duration: .15s;
    }

    h2 {
        margin: 0 0 10px 0;
        font-size: 1.5em;
    }

    img {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        height: 100%;
        margin: auto;
        vertical-align: top;
        z-index: -1;
        transition: opacity 0.30s;
        -webkit-mask-image: linear-gradient(to left, transparent 0%, #FFF 75%);
        mask-image: linear-gradient(to left, transparent 0%, #FFF 75%);
    }

    .writing-meta {
        margin: 0;
        padding: 0;
        list-style: none;
        color: #FFF;
        font-size: 0.90em;

        > li {
            display: inline-block;
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: 897px) {
    .BlogList {
        > li {
            &:hover {
                img {
                    opacity: 0.8;
                }
            }
        }

        a {
            padding-left: 15px;
        }

        h2 {
            font-size: 1.5em;
        }

        img {
            opacity: 0.3;
        }
    }
}