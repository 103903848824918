.App {
  width: 1300px;
  height: 725px;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  padding: 30px 15px;
  position: relative;
  box-sizing: border-box;
  font-size: 16px;
  color: #2d3356bf;
}

.frame {
  width: 100%;
  height: 100%;
  border: solid 1px;
  border-image-slice: 1;
  border-top: 0;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
}

.content {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
}

.page {
  margin: auto;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  > *:not(.FloatingView) {
    transition-property: transform, filter;
    transition-duration: .20s;
  }
}

.layouts:not(.effects-off) {
  border: solid 3px rgba(255, 255, 255, 0.03);
  box-shadow: 0px 0px 30px black;
  border-radius: 30px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.005);
}

.my-portrait {
  position: absolute;
  bottom: 0px;
  left: -70px;
  height: 110%;
  min-height: 500px;
  max-width: 100%;
}



// Navigation
nav {

  // Desktop nav
  &.nav-desktop {
    height: 50px;
    width: calc(100% - 30px);
    position: absolute;
    margin-top: -26px;
    display: flex;
    z-index: 2;
    font-size: 1.20em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    ul {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;
    }

    li {
      display: inline-block;
      align-self: center;
      text-align: center;
      white-space: nowrap;
      font-weight: 500;
    }

    li:not(.nav-separator) {
      padding: 0px 10px;
      width: fit-content;
    }

    li.nav-separator {
      height: 1px;
      flex-basis: 30px;
      min-width: 30px;
      margin-top: 1px;
      background-attachment: fixed;
      mix-blend-mode: difference;

      &:first-child {
        min-width: 30px;
      }

      &:last-child {
        flex-basis: 100%;
      }
    }

    a {
      color: #FFF;
      text-decoration: none;
    }

    // Active page nav indicator
    .nav-active-page-indicator {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-attachment: fixed;
      mix-blend-mode: difference;
      position: absolute;
      transition-property: left;
      transition-duration: .30s;
      transition-timing-function: ease-out;
    }
  }

  // Mobile Navigation
  &.nav-mobile {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 0px;
    width: 100%;
    border-top: solid 1px;
    border-bottom: 0;
    border-left: 0;
    border-image-slice: 1;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 70px;
      height: 70px;
      top: -1px;
      right: 0px;
      float: right;
      position: relative;
      border-bottom-left-radius: 70px;
      cursor: pointer;
      color: #FFF;
      background-attachment: fixed;
      padding-top: 55px;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
      transition-property: opacity;
      transition-duration: .15s;
    }
  
    li:not(:first-child) {
      padding: 10px 20px;
      box-sizing: border-box;
      font-weight: 800;
      font-size: 16px;
      transition: margin-left .10s;
    }
  
    li:first-child {
      color: #FFF;
      text-align: center;
      display: block;
      font-size: 22px;
      position: absolute;
      right: 0px;
      top: 0px;
      padding: 11px 17px 28px 34px;
      box-sizing: border-box;
    }
  
    a {
      display: block;
  
      &.active {
        position: relative;
  
        &:before {
          content: "";
          position: absolute;
          left: -10px;
          top: 0px;
          bottom: 0px;
          margin: auto;
          border-radius: 5px;
          width: 5px;
          height: 5px;
          background-color: #FFF;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}



// Page Gradients
.aboutme-gradient {
  color: #557CFF;
  background: -webkit-gradient(linear, left top, right top, from(#3af4f5), to(#164dfe));
  background: -webkit-linear-gradient(left, #3af4f5 0%, #164dfe 50%);
  background: -o-linear-gradient(left, #3af4f5 0%, #164dfe 50%);
  background: linear-gradient(to right, #3af4f5 0%, #164dfe 50%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.portfolio-gradient {
  color: #06b5ca;
  background: -webkit-gradient(linear, left top, right top, from(#c9fa84), to(#06b5ca));
  background: -webkit-linear-gradient(left, #c9fa84 0%, #06b5ca 50%);
  background: -o-linear-gradient(left, #c9fa84 0%, #06b5ca 50%);
  background: linear-gradient(to right, #c9fa84 0%, #06b5ca 50%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
.App[data-path="/portfolio"] .article a {color: #06b5ca}

.writings-gradient {
  color: #a800fe;
  background: -webkit-gradient(linear, left top, right top, from(#ff615c), to(#f0de72));
  background: -webkit-linear-gradient(left, #ff615c 0%, #f0de72 50%);
  background: -o-linear-gradient(left, #ff615c 0%, #f0de72 50%);
  background: linear-gradient(to right, #ff615c 0%, #f0de72 50%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
.App[data-path="/writings"] .article a {color: #ff615c}

.contact-gradient {
  color: #FF3CAC;
  background: -webkit-gradient(linear, left top, right top, from(#FF3CAC), to(#8700ff));
  background: -webkit-linear-gradient(left, #FF3CAC 0%, #8700ff 50%);
  background: -o-linear-gradient(left, #FF3CAC 0%, #8700ff 50%);
  background: linear-gradient(to right, #FF3CAC 0%, #8700ff 50%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
.App[data-path="/contact"] .article a {color: #FF3CAC}



// Media Queries
@media screen and (max-width: 1100px) {
  img.my-portrait {
    height: 87%;
  }
}

@media screen and (max-width: 930px) {
  .App {
    height: 100vh;
  }
}

@media screen and (max-width: 750px) {
  .App {
    font-size: 16px;
  }
}

@media screen and (max-width: 665px) {
  .App {
    padding: 10px;
    padding-top: 20px;
    margin: initial;
    height: auto;
    font-size: 14px;
  }

  nav.nav-desktop {
    width: calc(100% - 20px);

    li {
      font-size: 1em;

      &:not(.nav-separator) {
        padding: 0px 5px;
      }

      &.nav-separator {
        min-width: 15px;

        &:first-child {
          min-width: initial;
          flex-basis: 100%;
        }

        &:last-child {
          min-width: initial;
        }
      }
    }

    .nav-active-page-indicator {
      top: 9px;
    }
  }
}

@media screen and (max-width: 450px) {
  .App {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  nav.nav-desktop {
    width: 100%;
  }

  .content {
    left: 10px;
    top: 20px;
    width: calc(100% - 20px);
    height: calc(100% - 30px);

    > .page {
      > .layouts:not(.effects-off):not(.padding-off) {
        padding: 20px;
      }
    }
  }

  .FloatingView {
    right: -5px;
    bottom: -5px;
  }

  .article {
    padding: 30px 15px;
  }
}

@media screen and (max-width: 430px) {
  .App {
    padding-top: 0px;
  }

  nav.nav-desktop {
    display: none;
  }

  nav.nav-mobile {
    display: block;
    z-index: 100;
  }

  /* adjust content to allow normal scrolling */
  .layouts.scroll-y {
    overflow: visible;
  }

  .content {
    position: relative;
    height: auto;
    top: 0px;
    padding: 10px 0px;
  }

  .page {
    height: auto;
    position: relative;
    bottom: auto;

    > .layouts {
      min-height: calc(100vh - 21px);
    }
  }

  .frame {
    height: auto;
    min-height: 100vh;
  }
}