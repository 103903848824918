.MyIntro {
    width: 100%;
    box-sizing: border-box;
    color: #FFF;
    margin: 30px 0px;

    p {
        line-height: 2.3;
        font-size: 0.95em;
    }

    .my-name {
        font-family: 'Poppins', sans-serif;
        font-size: 1.88em;
        font-weight: 600;
        margin-top: 0px;
        margin-bottom: 5px;
    }

    .my-title {
        margin: 0;
        font-size: 1em;

        span {
            font-size: .7em;
            color: #5d5d5d;
            font-weight: 400;
            -webkit-text-fill-color: #5d5d5d;
        }
    }
    
    .my-bio {
        margin: 35px 0px;
    }
}