.Form {
    width: 100%;
    color: #FFF;
    overflow: hidden;
    display: flex;
    height: 100%;
    flex-direction: column;

    > div {
        position: relative;
    }

    input, textarea {
        width: 100%;
        height: 55px;
        border: none;
        outline: none;
        padding: 0px 15px;
        font-size: 18px;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 30px;
        font-family: 'Poppins', sans-serif;
    }

    textarea {
        height: 250px;
        padding: 15px;
        margin-bottom: 0px;
        resize: none;
    }

    label {
        font-size: 1em;
        line-height: 1;
        margin-bottom: 10px;
        display: block;
    }

    .form-input-col {
        width: 100%;
        display: flex;

        > div {
            width: 100%;

            &:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .form-textarea {
        position: relative;
        z-index: 0;
    }

    .contact-form-submit-btn {
        position: absolute;
        top: 0px;
        bottom: 0px;
        font-size: 1.7em;
        margin: auto;
        display: block;
        border-radius: 100px;
        background: linear-gradient(to right, #FF3CAC, #8700ff);
        width: auto;
        left: 0px;
        right: 0px;
        color: #FFF;

        &:hover {
            background: linear-gradient(to right, #8700ff, #FF3CAC);
        }
    }
}

.form-container {
    &:empty {
        &:after {
            content: "select an option";
            top: 0px;
            bottom: 0px;
            margin: auto;
            height: 25px;
            position: absolute;
            left: 0px;
            right: 0px;
            display: block;
            text-align: center;
            color: #FFF;
            font-size: 1em;
        }
    }
}

.contact-form-submit-btn {
    margin: 0 auto;
    font-size: 1.7em;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 930px) {
    .Form {
        .contact-form-submit-btn {
            position: relative;
        }
    }
}

@media screen and (max-width: 450px) {
    .Form {
        .form-input-col {
            flex-direction: column;
        }

        .contact-form-submit-btn {
            margin: 30px auto;
        }
    }
}