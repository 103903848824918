.article {
    margin-bottom: 30px;
    overflow: hidden;
    padding: 40px 30px;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background-color: #FFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-right: none;

    &.project-article {
        margin-top: 250px;
    }

    .title {
        font-size: 2.5em;
    }

    .heading {
        margin: 30px 0px 10px 0px;
    }

    p {
        line-height: 2;
        font-weight: 400;
        margin-bottom: 0;
    }

    p:first-child {
        margin-top: 0;
    }

    h1, h2, h3, h4, h5, h6 {color: #464646}

    img {
        max-width: 100%;
    }

    .article-img-col-3 {
        width: 33.3333%;
        display: inline-block;
    }
}